<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">
          <h1 class="text-h4">{{ $lang.header.globalSettings }}</h1>
          <div class="d-inline-flex align-center">
            <div>
              <v-btn
                text
                light
                color="primary"
                class="ml-2"
                @click="$emit('fetchGlobalSettings', true)"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider class="mt-3"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div><v-icon class="mr-2">{{ iconHandler(item.type) }}</v-icon>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: 'globalSettingsEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      headers: [
        { text: this.$lang.labels.type, value: 'type', sortable: false },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchGlobalSettings', true)
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    this.$emit('fetchGlobalSettings', true)

    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    iconHandler(type) {
      switch (type) {
      case 'PROCESSING':
        return 'mdi-flip-to-back'
      case 'FRONTEND':
        return 'mdi-flip-to-front'
      default:
        return 'mdi-cog-outline'
      }
    }
  }
}
</script>
