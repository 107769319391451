<template>
  <div style="width: 100%;">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="settings"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @fetchGlobalSettings="fetchSettings()"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './GlobalSettingsDatatable'
import {
  getGlobalSettingsUsingGET as getSettings
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      tableKey: 0,
      loading: false,
      settings: []
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly

      this.fetchSettings(event.options)
    },
    fetchSettings() {
      this.loading = true

      getSettings()
        .then((res) => {
          this.settings = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
